var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-card',[_c('validation-observer',{ref:"cloneForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"cloneForm",attrs:{"form":_vm.$refs.cloneForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"objectives",fn:function(){return [_c('b-tabs',{staticClass:"tab-card mt-2",attrs:{"pills":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.objectiveTypes),function(type,index){return _c('b-tab',{key:(type + "-" + index),on:{"click":function($event){return _vm.getCurrentTab(type)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.getTranslate(type, "title", _vm.$i18n.locale))+" ")])]},proxy:true}],null,true)},[[_c('okr-setting-header',{staticClass:"mt-3"}),_c('app-collapse',{attrs:{"accordion":"","type":"margin"}},_vm._l((_vm.objectives.filter(
                        function (item) { return item.objectiveTypeId === _vm.objectiveType.id; }
                      )),function(item,index){return _c('app-collapse-item',{key:(item + "-" + index),staticClass:"mb-1",class:{
                        'bg-background': index % 2 != 0,
                      },attrs:{"is-visible":true,"title":(item + "-" + index)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('okr-setting-title',{attrs:{"no":index + 1},scopedSlots:_vm._u([{key:"description",fn:function(){return [(item.objective)?_c('p',{staticClass:"hint-text"},[_vm._v(" "+_vm._s(_vm.getTranslate( item.objective, "description", _vm.$i18n.locale ))+" ")]):_vm._e()]},proxy:true},{key:"title",fn:function(){return [(
                                item.titleEn == null &&
                                (item.objective != null
                                  ? item.objective.titleEn == null
                                  : true)
                              )?[_c('b-form-input',{attrs:{"autocomplete":"off"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]:[_c('n-text-view',{attrs:{"value":_vm.getTranslate(
                                    item.objective,
                                    'title',
                                    _vm.$i18n.locale
                                  )}})]]},proxy:true},{key:"weight",fn:function(){return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{ref:"weight",refInFor:true,attrs:{"type":"number","autocomplete":"off","maxLength":"2"},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})],1)]},proxy:true}],null,true)})]},proxy:true}],null,true)},[_c('hr'),_c('okr-setting-description',{scopedSlots:_vm._u([{key:"description",fn:function(){return _vm._l((item.objectiveKeyResults),function(result,idx){return _c('div',{key:(result + "-" + idx),staticClass:"mb-1"},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('label',[_vm._v("#"+_vm._s(idx + 1)+".")])]),_c('b-col',[_c('b-form-textarea',{staticClass:"pb-2",attrs:{"rows":"2","max-rows":"6","placeholder":_vm.$t('field.objectiveKeyResult'),"value":result.title},model:{value:(result.title),callback:function ($$v) {_vm.$set(result, "title", $$v)},expression:"result.title"}})],1)],1)],1)})},proxy:true}],null,true)})],1)}),1),_c('okr-setting-footer',{attrs:{"total-weight":_vm.calculateWeight(_vm.objectiveType.id)}})]],2)}),1)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.tabIndex == _vm.objectiveTypes.length - 1)?[(_vm.$can('update', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route,"hide-create":true},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e()]:_vm._e(),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],2)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }